import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "pb-4" }
const _hoisted_2 = { class: "fs-1 fw-bold" }
const _hoisted_3 = { class: "fs-6 text-gray-400 m-0" }
const _hoisted_4 = { class: "svg-icon svg-icon-4 svg-icon-gray-600 ms-2" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-header border-0 pt-6" }
const _hoisted_7 = { class: "card-title" }
const _hoisted_8 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_9 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "card-toolbar" }
const _hoisted_12 = {
  key: 0,
  class: "d-flex justify-content-end gap-2",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_13 = { class: "card-body pt-0" }
const _hoisted_14 = { class: "fs-7 text-gray-400 mw-100px text-line-clamp-1" }
const _hoisted_15 = { class: "mw-200px d-flex flex-column gap-1 text-dark" }
const _hoisted_16 = { class: "fw-bolder" }
const _hoisted_17 = { class: "fs-7 text-gray-400" }
const _hoisted_18 = { class: "fs-7 text-gray-400" }
const _hoisted_19 = { class: "fs-7 text-gray-400" }
const _hoisted_20 = { class: "d-flex justify-content-end gap-2" }
const _hoisted_21 = { class: "badge badge-light-success" }
const _hoisted_22 = { class: "badge badge-light-danger" }
const _hoisted_23 = { class: "badge badge-light-warning" }
const _hoisted_24 = { class: "fs-7 text-gray-400" }
const _hoisted_25 = { class: "d-flex justify-content-end gap-2" }
const _hoisted_26 = ["disabled", "onClick"]
const _hoisted_27 = { class: "svg-icon svg-icon-2" }
const _hoisted_28 = {
  key: 1,
  class: "spinner-border spinner-border-sm align-middle"
}
const _hoisted_29 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_ProductException = _resolveComponent("ProductException")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatablePlus = _resolveComponent("MBDatatablePlus")!
  const _directive_auth = _resolveDirective("auth")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.options.defaultWarehouse.name), 1),
      _createElementVNode("p", _hoisted_3, [
        _createElementVNode("span", null, _toDisplayString(_ctx.options.defaultWarehouse.country), 1),
        _createVNode(_component_el_popover, {
          placement: "top",
          width: "auto",
          trigger: "hover"
        }, {
          reference: _withCtx(() => [
            _createElementVNode("span", _hoisted_4, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen044.svg" })
            ])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.options.defaultWarehouse.address), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", _hoisted_9, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _withDirectives(_createElementVNode("input", {
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
              onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
              class: "form-control form-control-solid w-250px ps-14",
              placeholder: _ctx.t('common.search')
            }, null, 40, _hoisted_10), [
              [_vModelText, _ctx.search]
            ]),
            _withDirectives(_createElementVNode("span", {
              class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle cursor-pointer",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
            }, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
            ], 512), [
              [_vShow, _ctx.search != '']
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          (_ctx.checkedCompanys.length === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_component_el_select, {
                  class: "w-100px",
                  disabled: _ctx.loading,
                  modelValue: _ctx.options.exception,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.options.exception) = $event)),
                  onChange: _ctx.filterChange
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.exceptionOptions, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["disabled", "modelValue", "onChange"]),
                _createVNode(_component_el_date_picker, {
                  class: "w-375px bg-light-gray",
                  modelValue: _ctx.options.received_date,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.options.received_date) = $event)),
                  type: "daterange",
                  clearable: "",
                  "start-placeholder": _ctx.t('inspection.receivedDateStart'),
                  "end-placeholder": _ctx.t('inspection.receivedDateEnd'),
                  onChange: _ctx.filterChange
                }, null, 8, ["modelValue", "start-placeholder", "end-placeholder", "onChange"]),
                _createVNode(_component_el_select, {
                  class: "w-175px",
                  modelValue: _ctx.options.wms_inspection_status,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.options.wms_inspection_status) = $event)),
                  clearable: "",
                  "collapse-tags": "",
                  multiple: "",
                  placeholder: _ctx.t('common.status'),
                  onChange: _ctx.filterChange
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options.status, (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "onChange"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_MBDatatablePlus, {
          loading: _ctx.loading,
          "table-data": _ctx.tableData,
          "table-header": _ctx.tableHeader,
          "enable-items-per-page-dropdown": true,
          "current-page": _ctx.currentPage,
          total: _ctx.total,
          showExpandBtn: false,
          "rows-per-page": _ctx.pageSize,
          order: "desc",
          onCurrentChange: _ctx.onCurrentPageChange,
          onItemsPerPageChange: _ctx.onRowsPerPageChange,
          onSort: _ctx.onColumnSort
        }, {
          "cell-qc_number": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.qc_number) + " ", 1),
            _createVNode(_component_el_popover, {
              placement: "top",
              width: "auto",
              trigger: "hover"
            }, {
              reference: _withCtx(() => [
                _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.CommonArrayToString(item.request_number)), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", null, _toDisplayString(_ctx.CommonArrayToString(item.request_number)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          "cell-exception": _withCtx(({ row: item }) => [
            _createElementVNode("span", {
              class: _normalizeClass(["svg-icon svg-icon-2", {
              'svg-icon-warning': item.exception === 1,
            }])
            }, [
              _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen044.svg" })
            ], 2)
          ]),
          "cell-information": _withCtx(({ row: item }) => [
            _createVNode(_component_el_popover, {
              placement: "top",
              width: "auto",
              trigger: "hover",
              disabled: item.guid_exception.length === 0
            }, {
              reference: _withCtx(() => [
                _createElementVNode("span", {
                  class: _normalizeClass(["svg-icon svg-icon-2", {
                  'svg-icon-danger': item.guid_exception.length > 0,
                }])
                }, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen006.svg" })
                ], 2)
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.t("inspection.missingInfomation")) + ": ", 1),
                  _createVNode(_component_ProductException, {
                    class: "text-gray-400",
                    guidException: item.guid_exception
                  }, null, 8, ["guidException"])
                ])
              ]),
              _: 2
            }, 1032, ["disabled"])
          ]),
          "cell-tracking": _withCtx(({ row: item }) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, _toDisplayString(item.relation_shipment.tracking_no), 1),
              _createElementVNode("div", _hoisted_17, _toDisplayString(item.relation_shipment.__show.service_provider), 1)
            ])
          ]),
          "cell-received_date": _withCtx(({ row: item }) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatDate(item.received_date)), 1),
              _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.formatDateTime(item.received_date, "HH:mm:ss")), 1)
            ])
          ]),
          "cell-articles": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(_ctx.CommonArrayToString(item.relation_shipment.articles.brand)) + " ", 1),
            _createElementVNode("div", _hoisted_19, _toDisplayString(item.relation_shipment.articles.sku_quantity) + " SKUs ", 1)
          ]),
          "cell-quantity": _withCtx(({ row: item }) => [
            _createTextVNode(_toDisplayString(item.relation_shipment.quantity), 1)
          ]),
          "cell-result": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_20, [
              _createVNode(_component_el_popover, {
                placement: "top",
                width: "auto",
                trigger: "hover"
              }, {
                reference: _withCtx(() => [
                  _createElementVNode("span", _hoisted_21, _toDisplayString(item.relation_shipment.result.passed), 1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t("inspection.pass") +
                " " +
                item.relation_shipment.result.passed), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_el_popover, {
                placement: "top",
                width: "auto",
                trigger: "hover"
              }, {
                reference: _withCtx(() => [
                  _createElementVNode("span", _hoisted_22, _toDisplayString(item.relation_shipment.result.failed), 1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t("inspection.fail") +
                " " +
                item.relation_shipment.result.failed), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_el_popover, {
                placement: "top",
                width: "auto",
                trigger: "hover"
              }, {
                reference: _withCtx(() => [
                  _createElementVNode("span", _hoisted_23, _toDisplayString(item.relation_shipment.result.missing), 1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t("inspection.missingQty") +
                  " " +
                  item.relation_shipment.result.missing), 1)
                ]),
                _: 2
              }, 1024)
            ])
          ]),
          "cell-inspected_date": _withCtx(({ row: item }) => [
            _createElementVNode("div", null, [
              _createElementVNode("div", null, _toDisplayString(_ctx.formatDate(item.inspected_date)), 1),
              _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.formatDateTime(item.inspected_date, "HH:mm:ss")), 1)
            ])
          ]),
          "cell-inspection_status": _withCtx(({ row: item }) => [
            _createElementVNode("span", {
              class: _normalizeClass(["badge text-uppercase", _ctx.getInspectionStatusMap(item.inspection_status)])
            }, _toDisplayString(item.__show.inspection_status), 3)
          ]),
          "cell-actions": _withCtx(({ row: item }) => [
            _createElementVNode("div", _hoisted_25, [
              _withDirectives((_openBlock(), _createElementBlock("button", {
                class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                disabled: item.downloadDisabled,
                onClick: ($event: any) => (_ctx.fileExport(item.id, item))
              }, [
                _createElementVNode("span", _hoisted_27, [
                  (!item.downloadDisabled)
                    ? (_openBlock(), _createBlock(_component_inline_svg_icon, {
                        key: 0,
                        src: "media/icons/duotune/arrows/arr044.svg"
                      }))
                    : (_openBlock(), _createElementBlock("span", _hoisted_28))
                ])
              ], 8, _hoisted_26)), [
                [_directive_auth, { auth: ['export'] }]
              ]),
              _createVNode(_component_router_link, {
                to: '/warehouse-management/inspection/' + item.id + '/overview',
                class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_29, [
                    _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/art/art005.svg" })
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ])
          ]),
          _: 1
        }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onCurrentChange", "onItemsPerPageChange", "onSort"])
      ])
    ])
  ], 64))
}