
import { defineComponent, ref, onMounted, onActivated, onUpdated } from "vue";
import { MBDatatablePlus } from "magicbean-saas-common";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiInspection } from "@/core/api";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import {
  FilterOption,
  SortOption,
  TaggingItem,
} from "@/core/directive/interface/common";
import { InspectionTable } from "@/core/directive/interface/inspection";
import store from "@/store";
import {
  CommonArrayToString,
  commonChangeFilterAddDate,
  commonExportFile,
  formatDate,
  formatDateTime,
  getDiffDays,
  getLateDays,
  setModuleBCN,
} from "@/core/directive/function/common";
import { useRoute } from "vue-router";
import { getInspectionStatusMap } from "@/core/directive/function/inspection";
import {
  getUserWarhouseDescription,
  getUserWarhouseName,
} from "@/core/directive/function/user";
import { Mutations } from "@/store/enums/StoreEnums";
import ProductException from "./ProductException.vue";
export default defineComponent({
  name: "warehouse-management-inspection",
  components: {
    MBDatatablePlus,
    ProductException,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const receivingPackageRef = ref();
    const editRef = ref();
    const itemId = ref(0);

    const loading = ref(true);
    const disabledExportPackageMismatchedReports = ref(false);
    const tableData = ref<Array<InspectionTable>>([]);
    const checkedCompanys = ref([]);
    const currentPage = ref<number>(1);
    const total = ref<number>(1);
    const pageSize = ref<number>(
      store.getters.currentConfigPageSize(route.name)
    );
    const search = ref<string>("");
    const filterOptions = ref<Array<FilterOption>>([]);
    const sortOptions = ref<Array<SortOption>>([
      { field: "id", direction: "desc" },
    ]);

    const tableHeader = ref([
      {
        name: t("inspection.inboundNo"),
        key: "qc_number",
        sortable: true,
      },
      {
        name: t("inspection.exception"),
        key: "exception",
        sortable: false,
      },
      {
        name: t("common.information"),
        key: "information",
        sortable: false,
      },
      {
        name: t("inspection.tracking"),
        key: "tracking",
        sortable: false,
      },
      {
        name: t("inspection.receivedDate"),
        key: "received_date",
        sortable: false,
      },
      {
        name: t("inspection.articles"),
        key: "articles",
        sortable: false,
      },
      {
        name: t("inspection.qty"),
        key: "quantity",
        align: "right",
        sortable: false,
      },
      {
        name: t("inspection.result"),
        key: "result",
        align: "right",
        sortable: false,
      },
      {
        name: t("inspection.inspectedDate"),
        key: "inspected_date",
        align: "right",
        sortable: false,
      },
      {
        name: t("inspection.status"),
        key: "inspection_status",
        sortable: false,
      },
      {
        name: t("common.action"),
        key: "actions",
        sortable: false,
      },
    ]);

    const options = ref({
      received_date: "",
      id: "",
      wms_inspection_status: [],
      status: [] as TaggingItem[],
      exception: 0,
      exceptionOptions: [
        {
          value: 0,
          label: t("inspection.exceptionAll"),
        },
        {
          value: 1,
          label: t("inspection.exceptionException"),
        },
        {
          value: 2,
          label: t("inspection.missingInfomation"),
        },
      ],
      defaultWarehouse: {
        address: "",
        country: "",
        name: "",
      },
    });

    const init = () => {
      setModuleBCN(t, route);
      getInspectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
      if (options.value.status.length === 0) {
        getTaggingData();
      }
    };

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["wms_inspection_status"],
      });
      if (data.code == 0) {
        options.value.status = data.data.wms_inspection_status.items;
      }
    };

    const filterChange = () => {
      handleFilter(options.value);
      updateList();
    };

    onMounted(() => {
      if (store.getters.getAliveList.length === 0) {
        init();
      }
    });

    onActivated(() => {
      init();
    });

    onUpdated(() => {
      MenuComponent.reinitialization();
    });

    const getInspectionList = (
      page: number,
      pageSize: number,
      search?: string,
      filterOptions?: Array<FilterOption>,
      sortOptions?: Array<SortOption>
    ) => {
      loading.value = true;
      ApiInspection.getInspectionList({
        page: page,
        page_size: pageSize,
        search_value: search,
        filter_group: filterOptions,
        sort_orders: sortOptions,
        exception: options.value.exception,
      })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            tableData.value.splice(
              0,
              tableData.value.length,
              ...data.data.items
            );

            total.value = data.data.total;
            options.value.defaultWarehouse = data.data.default_warehouse;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const updateList = () => {
      getInspectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onCurrentPageChange = (page: number) => {
      currentPage.value = page;
      getInspectionList(
        page,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const onRowsPerPageChange = (pagesize: number) => {
      pageSize.value = pagesize;
      updateList();
      store.commit(Mutations.SET_CURRENT_PAGE_CONFIG, {
        key: route.name,
        data: {
          pageSize: pageSize.value,
        },
      });
    };

    const onColumnSort = (val) => {
      let sortObj: SortOption = {
        field: val.columnName,
        direction: val.order,
      };
      sortOptions.value.splice(0, sortOptions.value.length, sortObj);
      getInspectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const editItem = (id) => {
      itemId.value = id;
      editRef.value?.modalShow();
    };

    const handleResetItem = () => {
      itemId.value = 0;
    };

    const showReceivingPackage = () => {
      receivingPackageRef.value?.modalShow();
    };

    const handleFilter = (val) => {
      let filterArr: Array<FilterOption> = [];
      for (let item in val) {
        if (val[item] != null) {
          if (val[item].length != 0) {
            if (item == "received_date") {
              filterArr.push({
                field: "received_date",
                value: commonChangeFilterAddDate(val[item][0], 1),
                condition: "gteq",
              });
              filterArr.push({
                field: "received_date",
                value: commonChangeFilterAddDate(val[item][1]),
                condition: "lteq",
              });
            } else if (item == "id") {
              filterArr.push({
                field: "id",
                value: val[item],
                condition: "eq",
              });
            } else if (item == "wms_inspection_status") {
              filterArr.push({
                field: "inspection_status",
                value: val[item],
                condition: "in",
              });
            }
          }
        }
      }
      filterOptions.value.splice(0, filterOptions.value.length, ...filterArr);
      search.value = "";
      currentPage.value = 1;
    };

    const handleFilterReset = () => {
      resetFilter();
      getInspectionList(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const resetFilter = () => {
      filterOptions.value.splice(0, filterOptions.value.length);
    };

    const debounceSearch = _.debounce(getInspectionList, 1000);

    const searchItems = () => {
      currentPage.value = 1;
      debounceSearch(
        currentPage.value,
        pageSize.value,
        search.value,
        filterOptions.value,
        sortOptions.value
      );
    };

    const removeSearch = () => {
      search.value = "";
      currentPage.value = 1;
      updateList();
    };

    const fileExport = (id, item) => {
      item.downloadDisabled = true;
      ApiInspection.getInspectionFile({
        id: id,
      })
        .then((data) => {
          item.downloadDisabled = false;
          commonExportFile(data);
          updateList();
        })
        .catch((error) => {
          item.downloadDisabled = false;
          console.log(error);
        });
    };

    return {
      t,
      getDiffDays,
      getLateDays,
      CommonArrayToString,
      formatDate,
      formatDateTime,
      getInspectionStatusMap,
      getUserWarhouseName,
      getUserWarhouseDescription,
      receivingPackageRef,
      editRef,
      itemId,
      loading,
      tableHeader,
      tableData,
      currentPage,
      total,
      pageSize,
      search,
      checkedCompanys,
      options,
      editItem,
      handleResetItem,
      showReceivingPackage,
      getInspectionList,
      onCurrentPageChange,
      onRowsPerPageChange,
      onColumnSort,
      searchItems,
      removeSearch,
      handleFilter,
      handleFilterReset,
      updateList,
      fileExport,
      filterChange,
      disabledExportPackageMismatchedReports,
    };
  },
});
